import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"flex","margin-bottom":"15px","width":"100%","height":"50px"},style:(("justify-content:" + (_vm.styles?'space-between':'flex-end')))},[(_vm.styles)?_c('div',[_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","dense":""},on:{"click":_vm.toggleStyles}},[_c(VIcon,[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v("Back to content")])],1)],1):_vm._e(),_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}])},[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticStyle:{"cursor":"pointer"},on:{"click":_vm.toggleStyles}},[_vm._v(" "+_vm._s(_vm.styles?'Content':'Style')+" settings")])],1),_c(VListItem,[_c(VListItemTitle,{staticStyle:{"cursor":"pointer"},on:{"click":_vm.openCopy}},[_vm._v("Copy content from "+_vm._s(_vm.landing.advanced.default_lang))])],1)],1)],1)],1),(_vm.styles)?_c('div',[[_c('TypeEventsStyles',{attrs:{"styles":_vm.content.styles}})]],2):_vm._e(),(!_vm.styles)?_c('div',[_c('Editor',{attrs:{"api-key":"kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh","init":_vm.options},model:{value:(_vm.content.text),callback:function ($$v) {_vm.$set(_vm.content, "text", $$v)},expression:"content.text"}}),_c('p',{staticStyle:{"margin":"15px 0"}},[_vm._v(" To display the calendar, fill in the "),_c('router-link',{attrs:{"to":("/events?id=" + (_vm.$route.params.id))}},[_vm._v("events")])],1)],1):_vm._e(),_c('PopupConfirm',{ref:"PopupConfirm",attrs:{"title":("Are you sure about copy content from " + _vm.defaultLangName + " to " + _vm.currentLangName)},on:{"confirm":_vm.confirmCopy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }